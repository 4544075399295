.static-v1 {
	.gromitz-image {
		height: 200px;

		.img-responsive {
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
	}
}